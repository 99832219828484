import Vue from 'vue'
import ElementUI from 'element-ui';
import 'reset-css'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import Contextmenu from "vue-contextmenujs"
import VueRouter from 'vue-router'
import Vuex from "vuex"
import store from './store'

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(Contextmenu);
Vue.use(Vuex)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/search' },
    { path: '/search', component: require('./components/Search').default },
    { path: '/make', component: require('./components/Make').default },
    { path: '/setting', component: require('./components/setting/index').default },
  ]
})

Vue.$store = Vue.prototype.$store = store

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
