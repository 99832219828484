import registerGlobalShortcut from "../../common/registerGlobalShortcut";

export default {
    namespaced: true,
    state: {
        hotkey: 'Shift+Meta+D',
        startup: false
    },
    mutations: {
        save(state, payload) {
            Object.assign(state, payload)
        }
    },
    actions: {
        save({commit}, payload) {
            commit('save', payload)
        },
        init({state}) {
            registerGlobalShortcut(state.hotkey)
        }
    }
}
