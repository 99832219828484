import axios from "axios";
import $ from 'jquery'

export default function getImageTasks(keyword, page_num = 1) {
    return [
        axios.get(
            "https://www.52doutu.cn/api/?types=search&action=searchpic&limit=60&wd=" + keyword + "&offset=" + (page_num - 1)
        )
            .then(data => {
                return Promise.resolve(data.data.rows)
            }),

        axios.get(
            "https://www.doutula.com/search?type=photo&more=1&keyword=" + keyword + "&page=" + page_num
        )
            .then(data => {
                return Promise.resolve(
                    Array.from($(data.data).find('img[data-original]')).map(item => {
                        return {
                            url: item.dataset.original
                        }
                    })
                )
            }),

        axios.get(
            "https://pic.sogou.com/pics/json.jsp?query=" + keyword + "&st=5&start=" + (page_num - 1) * 16 + "&xml_len=16"
        )
            .then(data => {
                return Promise.resolve(data.data.items.map(item => {
                    return {
                        url: item.picUrl
                    }
                }))
            }),

        axios.get(
            "https://fabiaoqing.com/search/search/keyword/" + keyword + "/type/bq/page/" + page_num + ".html"
        )
            .then(data => {
                return Promise.resolve(
                    Array.from($(data.data).find('img[data-original]')).map(item => {
                        return {
                            url: item.dataset.original
                        }
                    })
                )
            })
    ]
}