<template>
  <div class="make" v-loading="loading.loadImage" element-loading-text="正在处理中~">
    <div class="wrapper" :style="{display: images.length > 1 ? 'flex' : 'block'}">
      <MakeItem v-for="(item, index) in images"
                :imgSrc="item.url"
                :key="index"
                ref="items"
                :inlineForm="images.length === 1"
      ></MakeItem>
    </div>
    <div class="footer">
      <i class="el-icon-back"
         style="font-size: 24px; cursor: pointer"
         @click="$router.push('/search')"
      />
      <el-button @click="copyImage"
                 :loading="loading.copy"
                 type="primary"
      >
        生成图片并复制到剪切板
      </el-button>
      <i class="el-icon-s-tools"
         style="font-size: 24px; cursor: pointer"
         @click="$router.push('/setting')"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import copyImage from "../common/copyImage";
import SuperGif from 'libgif'
import MakeItem from './MakeItem'
import GIF from 'gif.js'

var Stream = function (data) {
  this.data = data;
  this.len = this.data.length;
  this.pos = 0;

  this.readByte = function () {
    if (this.pos >= this.data.length) {
      throw new Error('Attempted to read past end of stream.');
    }
    if (data instanceof Uint8Array)
      return data[this.pos++];
    else
      return data.charCodeAt(this.pos++) & 0xFF;
  };

  this.readBytes = function (n) {
    var bytes = [];
    for (var i = 0; i < n; i++) {
      bytes.push(this.readByte());
    }
    return bytes;
  };

  this.read = function (n) {
    var s = '';
    for (var i = 0; i < n; i++) {
      s += String.fromCharCode(this.readByte());
    }
    return s;
  };

  this.readUnsigned = function () { // Little-endian.
    var a = this.readBytes(2);
    return (a[1] << 8) + a[0];
  };
};

export default {
  components: {
    MakeItem
  },
  data() {
    return {
      imageUrl: this.$store.state.make.imageUrl,
      loading: {
        loadImage: false,
        copy: false
      },
      images: [],
    }
  },
  methods: {
    beforeUpload(file) {
      this.img = URL.createObjectURL(file)
    },
    async copyImage() {
      this.loading.copy = true
      const gif = new GIF({
        workers: 2,
        quality: 10,
        workerScript: 'https://cdn.bootcdn.net/ajax/libs/gif.js/0.2.0/gif.worker.js',
      });
      for(let index = 0 ; index < this.$refs.items.length; index++) {
        const item = this.$refs.items[index]
        item.canvas.discardActiveObject().renderAll();
        const canvas = item.canvas.getElement()
        const ctx = canvas.getContext('2d')
        ctx.width = canvas.width
        ctx.height = canvas.height
        gif.addFrame(ctx, {delay: this.images[index].delay * 10 , copy: true})
      }

      gif.on('finished', (blob) => {
        console.log('finished')
        copyImage(URL.createObjectURL(blob));
        this.$notify({
          title: '成功',
          message: '已复制到剪切板',
          type: 'success',
          duration: 1000
        });
        this.loading.copy = false
      });
      gif.render()
    },
    async isGif(url) {
      const img = document.createElement('img')
      img.src = url
      const data = await axios.get(url, {
        responseType: "arraybuffer"
      })
      const stream = new Stream(new Uint8Array(data.data))
      return stream.read(3) === 'GIF'
    },
    loadImage() {
      this.loading.loadImage = true
      return new Promise(async (resolve, reject) => {
        if (await this.isGif(this.imageUrl)) {
          const imgEl = document.createElement('img')
          imgEl.style.pointerEvents = 'none'
          imgEl.style.opacity = '0'
          // gif库需要img标签配置下面两个属性
          imgEl.setAttribute('rel:animated_src', this.imageUrl);
          imgEl.setAttribute('rel:auto_play', '0');
          document.body.appendChild(imgEl)
          const rub = new SuperGif({gif: imgEl});
          rub.load(() => {
            const frames = rub.get_frames()
            console.log('frames', frames)
            for (let i = 0; i < rub.get_length(); i++) {
              // 遍历gif实例的每一帧
              rub.move_to(i);
              this.images.push({
                url: rub.get_canvas().toDataURL(),
                delay: frames[i].delay || 1
              })
            }
            resolve()
          });
        } else {
          this.images.push({url: this.imageUrl, delay: 0})
          resolve()
        }
      })
          .finally(() => {
            this.loading.loadImage = false
          })
    }
  },
  created() {
    this.loadImage()
  },
  beforeRouteEnter(to, from, next) {
    if (Vue.$store.state.make.imageUrl) {
      next()
    }
  }
}
</script>

<style scoped>

.make {
  padding: 12px 0 40px;
}

.wrapper {
  overflow: auto;
}

.compare {
  display: flex;
  justify-content: center;
}

.item {
  margin-top: 12px;
}

.label {
  margin-bottom: 12px;
}

#output {
}

.form .el-form-item {
  width: 48%;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: white;
  z-index: 1;
  border-top: 1px solid #eee;
}
</style>
<style>
.jsgif {
  display: none;
}
</style>