import {remote} from "electron";
import {Notification} from 'element-ui'

const {globalShortcut} = remote

export default function (accelerator) {
    globalShortcut.unregisterAll()
    const result = globalShortcut.register(accelerator, () => {
        const mainWindow = remote.getGlobal('mainWindow')
        if (mainWindow.isVisible()) {
            if (mainWindow.isFocused()) {
                mainWindow.hide()
            } else {
                mainWindow.show()
            }
        } else {
            mainWindow.show()
        }
    })
    if (!result) {
        Notification.error({
            title: '失败',
            message: '该快捷键已被占用，请选用其他快捷键',
        })
    }
    return result
}