<template>
  <div class="search" v-loading="loading">
    <el-input
        v-model="keyword"
        placeholder="输入关键字进行搜索"
        class="searchInput"
        ref="searchInput"
        @blur="autoFocus"
        @keyup.enter.native="search"
        clearable
    />
    <div class="grid" ref="grid">
      <div
          v-for="(item, index) in dataList"
          :key="index"
          :style="`background-image: url(${item.url}); background-size: contain; backgroundPosition: center; background-repeat: no-repeat;`"
          @contextmenu.prevent="(e) => onContextmenu(e, index, item.url)"
          class="item"
          @click="e => copyImage(e, item.url)"
      >
        <img :src="item.url" style="opacity: 0; pointer-events: none;" @error="onError"/>
      </div>
    </div>
  </div>
</template>

<script>
import copyImage from "../common/copyImage";
import {remote} from 'electron'
import axios from 'axios'
import {mapActions} from 'vuex'
import getImageTasks from "../common/getImageTasks";

export default {
  name: "Search",
  data() {
    return {
      keyword: "",
      dataList: [],
      loading: false
    };
  },
  methods: {
    ...mapActions('make', ['setImageUrl']),
    onContextmenu(event, index, url) {
      this.$contextmenu({
        items: [
          {
            label: "替换文字",
            onClick: () => {
              this.setImageUrl(url)
              this.$router.push({
                path: '/make'
              })
            },
          },
          // {
          //   label: "替换人脸（开发中）",
          //   disabled: true
          // },
        ],
        event,
        //x: event.clientX,
        //y: event.clientY,
        customClass: "custom-class",
        zIndex: 3,
        minWidth: 100,
      });
      return false;
    },
    copyImage(e, url) {
      copyImage(url)
      remote.getCurrentWindow().hide()
    },
    autoFocus() {
      this.$refs.searchInput.focus();
    },
    async search() {
      this.loading = true
      try {
        if (this.keyword) {
          this.dataList = []
          const tasks = getImageTasks(this.keyword)
          tasks.forEach(task => {
            task.then(data => {
              this.dataList.push(...data)
            })
          })
          await Promise.race(tasks)
        } else {
          const data = await axios.get(
              "https://www.dbbqb.com/api/search/json?size=60"
          )
          this.dataList = data.data.map(item => {
            return {
              url: `http://image.dbbqb.com/${item.path}`
            }
          })
        }
        this.$refs.grid.scrollTop = 0
      } catch (e) {
        console.warn(e)
      }
      this.loading = false
    },
    onError(e) {
      e.target.parentElement.remove()
    }
  },
  mounted() {
    this.autoFocus()
  },
  created() {
    this.search()
  }
};
</script>

<style scoped>
.search {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.searchInput {
  width: 100%;
  margin-bottom: 12px;
}

.grid {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  padding-bottom: 16px;
}

.item {
  width: 150px;
  height: 150px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}

.item:hover {
  transform: scale(1.2);
}
</style>