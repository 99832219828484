export default {
    namespaced: true,
    state: {
        imageUrl: '',
    },
    mutations: {
        set(state, payload) {
            Object.assign(state, payload)
        }
    },
    actions: {
        setImageUrl({commit}, imageUrl) {
            commit('set', {
                imageUrl
            })
        }
    }
}
