<template>
  <div class="make-item">
    <div class="compare" v-loading="inProgress" element-loading-text="正在处理中~">
      <canvas ref="output" style="box-shadow: 0 0 10px #00000078;"/>
    </div>
    <el-collapse style="margin: 24px 12px;" v-if="blocks.length">
      <el-collapse-item v-for="(block,index) in blocks" :key="index" :title="`文字块：${block.text}`" :name="index">
        <el-form label-width="80px" size="small" class="form" :inline="inlineForm" label-position="left">
          <el-form-item label="显示/隐藏">
            <el-switch
                v-model="block.visible">
            </el-switch>
          </el-form-item>
          <el-form-item label="文字">
            <el-input v-model="block.text"/>
          </el-form-item>
          <el-form-item label="字号">
            <el-input-number v-model="block.fontSize"/>
          </el-form-item>
          <el-form-item label="文字颜色">
            <el-color-picker v-model="block.fill" show-alpha/>
          </el-form-item>
          <el-form-item label="背景色" style="margin-bottom: 0;">
            <el-color-picker v-model="block.backgroundColor" show-alpha/>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <el-tooltip content="新增文字块" placement="top" v-if="!inProgress">
      <el-button type="success"
                 icon="el-icon-plus"
                 circle
                 size="mini"
                 @click="addText"
                 style="margin-left: 12px;"
      ></el-button>
    </el-tooltip>
  </div>
</template>

<script>
import axios from 'axios'
import {fabric} from 'fabric'

export default {
  props: {
    maxWidth: {
      type: Number,
      default: 500
    },
    inlineForm: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: {
        loadImage: false,
        recognizeWords: false,
      },
      canvas: null,
      blocks: [],
    }
  },
  computed: {
    inProgress() {
      return Object.values(this.loading).find(item => item)
    }
  },
  methods: {
    // 识别
    async recognizeWords(imageBase64) {
      this.loading.recognizeWords = true
      const res = await axios.post('https://www.paddlepaddle.org.cn/paddlehub-api/image_classification/chinese_ocr_db_crnn_mobile', {
        image: imageBase64.replace('data:image/png;base64,', '')
      })
      this.blocks = res.data.result[0].data.map(item => {
        const box = item.text_box_position
        return {
          top: box[0][1],
          left: box[0][0],
          width: box[1][0] - box[0][0],
          height: box[2][1] - box[0][1],
          text: item.text,
          fontSize: box[2][1] - box[0][1],
          fill: '#000000',
          backgroundColor: '#ffffff',
          visible: true
        }
      })
      this.loading.recognizeWords = false
    },
    getTextInstance(block) {
      return new fabric.Textbox(block.text, {
        left: block.left,
        top: block.top,
        fontFamily: 'serif',
        fontSize: block.fontSize,
        fill: block.fill,
        backgroundColor: block.backgroundColor,
        width: block.width + 10,
        editable: false,
        visible: block.visible
      })
    },
    // 替换文字
    replaceWords() {
      this.blocks.forEach((block, index) => {
        // 获取背景色
        const ctx = this.canvas.getContext("2d")
        const colorData = ctx.getImageData(block.top - 1, block.left - 1, 1, 1).data;
        const backgroundColor = `rgb(${colorData.join(',')})`
        // 绘制新文字
        block.instance = this.getTextInstance(block);
        this.watchBlock(index)
        this.canvas.add(block.instance)
      })
    },
    loadImage() {
      this.loading.loadImage = true
      return new Promise(async (resolve, reject) => {
        const imgEl = document.createElement('img')
        imgEl.src = this.imgSrc
        imgEl.onload = () => {
          let width = imgEl.width
          let height = imgEl.height
          let scale = 1
          // 图片宽度如果超出500，则进行缩放
          if (width > this.maxWidth) {
            scale = 1 - (width - this.maxWidth) / width
            width = this.maxWidth
            height = scale * height
          }
          this.canvas.setWidth(width)
          this.canvas.setHeight(height)

          const image = new fabric.Image(imgEl, {
            top: 0,
            left: 0,
            selectable: false,
            scaleX: scale,
            scaleY: scale,
          });

          this.canvas.add(image);
          this.canvas.renderAll()
          resolve(this.canvas.toDataURL())
        }
        imgEl.onerror = function () {
          reject()
        }
      })
          .finally(() => {
            this.loading.loadImage = false
          })
    },
    watchBlock(index) {
      const whiteList = ['fontSize', 'fill', 'backgroundColor', 'text', 'visible']
      whiteList.forEach(key => {
        this.$watch(`blocks.${index}.${key}`, (newVal) => {
          this.blocks[index].instance.set(key, newVal)
          this.canvas.renderAll()
        })
      })
    },
    addText() {
      const block = {
        top: 0,
        left: 0,
        width: 100,
        height: 100,
        text: '请替换文字',
        fontSize: 24,
        fill: '#000000',
        backgroundColor: '#ffffff',
        visible: true,
      }
      block.instance = this.getTextInstance(block)
      this.canvas.add(block.instance)
      this.blocks.push(block)
      this.watchBlock(this.blocks.length - 1)
      this.canvas.renderAll()
      this.$notify.success({
        title: '成功',
        message: '新的文字块已添加到图片左上角'
      })
    }
  },
  async mounted() {
    this.canvas = new fabric.Canvas(this.$refs.output, {
      backgroundColor: '#ffffff'
    });
    const imageBase64 = await this.loadImage()
    await this.recognizeWords(imageBase64)
    this.replaceWords()
  }
}
</script>

<style scoped>
.make-item {
  margin: 0 12px 24px;
  min-width: 250px;
  flex-shrink: 0;
}

.compare {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.el-form--inline .el-form-item {
  width: 48%;
}
</style>