<template>
  <div :class="s.setting">
    <p :class="s.title">设置</p>
    <el-form label-width="120px" size="small" class="form" label-position="left" :class="s.form">
      <el-form-item label="快捷键">
        <el-input :value="hotkey" style="width: 200px;" @keydown.native="keydown"/>
      </el-form-item>
      <el-form-item label="开机自启">
        <el-switch v-model="startup"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import registerGlobalShortcut from '../../common/registerGlobalShortcut'
import {mapActions} from 'vuex'
import {remote} from 'electron'

export default {
  data() {
    return {
      hotkey: this.$store.state.setting.hotkey,
      startup: this.$store.state.setting.startup,
    }
  },
  computed: {
    hotkeyText() {
      const rs = new Set()
      if (this.hotkey.metaKey) {
        rs.add('Meta')
      }
      if (this.hotkey.shiftKey) {
        rs.add('Shift')
      }
      if (this.hotkey.ctrlKey) {
        rs.add('Control')
      }
      if (this.hotkey.altKey) {
        rs.add('Alt')
      }
      rs.add(this.hotkey.key)
      return Array.from(rs).join('+')
    },
  },
  methods: {
    ...mapActions('setting', ['save']),
    keydown(e) {
      e.preventDefault()
      const rs = new Set()
      if (e.metaKey) {
        rs.add('Meta')
      }
      if (e.shiftKey) {
        rs.add('Shift')
      }
      if (e.ctrlKey) {
        rs.add('Control')
      }
      if (e.altKey) {
        rs.add('Alt')
      }
      rs.add(e.key)
      this.hotkey = Array.from(rs).join('+')
    },
    submit() {
      if (registerGlobalShortcut(this.hotkey)) {
        this.save({
          hotkey: this.hotkey,
        })
      }
      remote.app.setLoginItemSettings({
        openAtLogin: this.startup,
        openAsHidden: true
      })
      this.save({
        startup: this.startup,
      })
      this.$notify.success({
        title: '成功',
        message: '设置已保存',
        duration: 1000
      })
    }
  }
}
</script>
<style lang="scss" module="s">
.setting {
  padding: 24px;
  font-size: 13px;
  color: #444;

  .title {
    font-size: 20px;
    color: #303133;
    padding-bottom: 16px;
    border-bottom: 1px solid #F2F6FC;
  }

  .form {
    margin-top: 16px;
  }
}
</style>
