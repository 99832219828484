<template>
  <div id="app">
    <keep-alive include="Search">
      <router-view/>
    </keep-alive>
    <i class="el-icon-back"
       style="position: fixed; left: 8px; bottom: 16px;font-size: 24px; cursor: pointer"
       @click="$router.push('/search')"
       v-if="$route.path !== '/search'"
    />
    <el-tooltip content="加入QQ群，反馈问题">
      <i class="el-icon-chat-line-square"
         style="position: fixed; right: 8px; bottom: 48px; font-size: 24px; cursor: pointer"
         @click="joinQQ"
      />
    </el-tooltip>
    <i class="el-icon-s-tools"
       style="position: fixed; right: 8px; bottom: 16px; font-size: 24px; cursor: pointer"
       @click="$router.push('/setting')"
       v-if="$route.path !== '/setting'"
    />
  </div>
</template>
<script>
import {shell} from 'electron'
export default {
  name: 'App',
  created() {
    this.$store.dispatch('setting/init')
  },
  methods: {
    joinQQ() {
      shell.openExternal('https://jq.qq.com/?_wv=1027&k=zNldMhzd')
    }
  }
}
</script>

<style>
html,
body,
#app {
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
* {
  box-sizing: border-box;
}
</style>
